<script>
	import { onMount } from 'svelte';

	export let downloadAppLink = '';
	export let icons = false;
	export let downloadNumbers = false;
	export let iconColor = 'white';
	export let className = '';
	export let small = false;
	export let id = '';

	onMount(() => {
		downloadAppLink = window.getDownloadTheAppLink(downloadAppLink);
	});
</script>

<a
	{id}
	href={downloadAppLink}
	class="inline-block group relative focus:ring-4 w-full md:w-fit focus:outline-none focus:ring-gray-300 nav-out"
	rel="noopener external"
>
	<button
		class={`click btn btn-primary w-full grid ${small ? 'px-3' : 'px-14'} ${className}`}
		class:pb-2={downloadNumbers}
	>
		<div class="flex items-center justify-center gap-x-4">
			<span class={`text-left text-ttl ${small ? 'text-xs' : ''}`}
				>{small ? 'Download app' : 'Download the app'}</span
			>
			{#if icons}
				<svg class="h-5" viewBox="0 0 44 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip0_1_22)">
						<path
							d="M14.5093 6.47622C14.3987 6.56181 12.5167 7.66125 12.5167 10.1015C12.5167 12.9149 14.9133 13.9156 14.9856 13.9419C14.975 13.9968 14.6029 15.3047 13.7225 16.6368C12.9314 17.8042 12.1085 18.9783 10.8602 18.9783C9.61191 18.9783 9.2823 18.2256 7.84051 18.2256C6.39872 18.2256 5.93301 18.9981 4.78469 18.9981C3.63636 18.9981 2.83892 17.9118 1.92238 16.5863C0.859118 15.0216 0 12.5945 0 10.2947C0 6.59911 2.32642 4.64162 4.61882 4.64162C5.83519 4.64162 6.84742 5.46895 7.61297 5.46895C8.37852 5.46895 9.47368 4.59334 10.8581 4.59334C11.3812 4.59334 13.2695 4.64162 14.5114 6.47622M10.2031 3.03085C10.7751 2.32861 11.177 1.35425 11.177 0.386479C11.177 0.246031 11.1664 0.116556 11.1409 0.00244141C10.2137 0.0397479 9.10792 0.641041 8.43594 1.44203C7.91069 2.06308 7.41946 3.03305 7.41946 4.02277C7.41946 4.16322 7.44498 4.31464 7.45561 4.36292C7.5194 4.37389 7.61297 4.38925 7.70654 4.38925C8.54014 4.38925 9.59277 3.80551 10.201 3.03085"
							fill={iconColor}
						/>
						<path
							d="M28.6763 3.01085V17.951C28.6763 18.0432 28.7486 18.1134 28.8379 18.1134C28.874 18.1134 28.9166 18.0981 28.9485 18.0695L36.487 10.4809L28.9485 2.89674C28.8847 2.82651 28.7868 2.8309 28.7188 2.89674C28.6933 2.92966 28.6763 2.96696 28.6763 3.01085Z"
							fill={iconColor}
						/>
						<path
							d="M39.6471 7.36468L30.1968 1.99254L30.1925 1.98815C30.0309 1.89598 29.8735 2.12421 30.0033 2.25368L37.4163 9.56796L39.6492 7.36687L39.6471 7.36468Z"
							fill={iconColor}
						/>
						<path
							d="M30.0054 18.7081C29.8735 18.8441 30.0266 19.0702 30.1883 18.978L30.1925 18.9736L39.6492 13.5971L37.4099 11.396L30.0032 18.7103L30.0054 18.7081Z"
							fill={iconColor}
						/>
						<path
							d="M43.462 9.53058L40.8208 8.02734L38.3413 10.483L40.8208 12.9342L43.462 11.431C44.1808 11.025 44.1808 9.94314 43.462 9.53277"
							fill={iconColor}
						/>
						<path d="M21.3865 3.67383V16.9923H21.7586V3.67383" fill={iconColor} />
						<path d="M22.0776 3.67383H21.0675V17.3214H22.0776V3.67383Z" fill={iconColor} />
					</g>
					<defs>
						<clipPath id="clip0_1_22">
							<rect width="44" height="19" fill={iconColor} />
						</clipPath>
					</defs>
				</svg>
			{/if}
		</div>
		{#if downloadNumbers}
			<div class="font-medium text-sm">5M+ users</div>
		{/if}
	</button>
</a>
